import React  from 'react';
import { FaInstagram, FaLinkedin, FaCalendar, FaEnvelope } from 'react-icons/fa';
import Gallery from './Gallery';
import './App.css';
import NavBar from './NavBar';

const Home = () => {
  return (
      <>
      <div className="hero-image" id="home">
          <h1 className="hero-text">Ida Mayer</h1>
          <div>
          <h2 className="hero-text">Friendly Neighborhood Polymath</h2>
          <h3 className="hero-text">Coach &middot; Acrobat &middot; Model &middot; Engineer &middot; Researcher</h3>
          </div>
      </div>

      <div className="button-container">
          <a href="https://coaching.idamayer.com" className="large-button" style={{backgroundImage: 'url("/img/grow-with-me.png")'}}>Grow with me</a>
          <a href="/classes" className="large-button" style={{backgroundImage: 'url("/img/train-with-me.png")'}}>Train with me</a>
          <a href="/cv.html" className="large-button" style={{backgroundImage: 'url("/img/awards-and-publications.png")'}}>Awards and Publications</a>
      </div>

      <div id="about" className="bio-section">
          <div className="bio-text">
          <h2 style={{display:'inline'}}>I seek out variety</h2>
          <p style={{display:'inline'}}> in my life. The things I'm focusing on right now are running my <a href="https://coaching.idamayer.com">productivity coaching</a> business, exploring new projects in environmental storytelling, and learning mandarin Chinese. 
          I'm a former Google software engineer with a relatively varied academic background - I hold 4 degrees spanning humanities, techonology, and neuroscience. 
          When I'm away from those interests I love making bizarre fusion foods, and teaching and training flexibility{/*<a href="/classes">flexibility</a>*/}.</p>
          </div>
          <div className="bio-image">
          <img src="./img/bio.jpg" alt="Ida Mayer" />
          </div>
      </div>

      <Gallery />

      <footer>
          <div className="social-icons">
          <a href="https://instagram.com/idamayeraesthetic" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
          <a href="https://linkedin.com/in/idamayer" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
          <a href="https://cal.com/idamayer" target="_blank" rel="noopener noreferrer"><FaCalendar /></a>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSfWCwbly-8mHm4v3Ax5FJARczXtxxqZmz1IG6QDleiW1Gd41Q/viewform"><FaEnvelope /></a>
          </div>
      </footer>
      </>
  );
};

const App = () => {
  return (
    <div className="webpage">
      <NavBar />
      <Home />
    </div>
  );
};

export default App;
